import "../../Styled/login.scss";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Link } from "react-router-dom";
import { BgImage, Error, ShowHidePwd } from "../../Components/UI/index";
import useAuthApp from "../../hooks/useAuthApp";
import { onSuccess, onFailedMsg } from "../../helper/showHideAlert";
import { authAction } from "../../store/auth/auth-slice";
import { onRememberMeHandlerMd } from "../../store/presist/presist-action";

const Login = () => {
  const { authFetch, navigate, dispatch } = useAuthApp();
  const { token } = useSelector((state) => state.auth);
  const { isRememberMe } = useSelector((state) => state.presist);
  const [btnLoading, setBtnLoading] = useState(false);

  const [values, setValues] = useState({
    mobileNumber: isRememberMe?.mobileNumber,
    password: isRememberMe?.password,
    check: isRememberMe?.flag,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (token) {
      navigate("/dashboard ");
    } else {
      localStorage.clear();
    }
  }, [navigate, token]);

  const handleChangeMobile = (value, data) => {
    delete data?.format;
    setValues((previousState) => {
      return {
        ...previousState,
        mobileNumber: value.slice(data.dialCode.length),
      };
    });
    setErrors((previousState) => {
      return {
        ...previousState,
        mobileNumber:
          value.slice(data.dialCode.length) === ""
            ? "Mobile Number is required"
            : "",
      };
    });
  };

  const onChnageValue = (e) => {
    setValues((previousState) => {
      return {
        ...previousState,
        [e.target.name]: e.target.value,
      };
    });

    setErrors((previousState) => {
      return {
        ...previousState,
        [e.target.name]:
          e.target.value === "" ? `${e.target.title} is required` : "",
      };
    });
  };

  const isFormValidate = () => {
    if (
      Number(values?.mobileNumber?.length) !== Number(10) ||
      values.mobileNumber === "" ||
      !values.password
    ) {
      setErrors({
        ...errors,
        mobileNumber:
          values.mobileNumber === ""
            ? "Mobile Number is required"
            : Number(values?.mobileNumber?.length) !== Number(10)
            ? "Invalid Mobile Number"
            : "",
        password: !values.password ? "Password is required" : "",
      });
      return false;
    }
    return true;
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (isFormValidate() && !btnLoading) {
      setBtnLoading(true);
      try {
        let formBody = {
          mobileNumber: values.mobileNumber,
          password: values.password,
        };
        const { data } = await authFetch.post("/user/login", formBody);
        console.log(data, "formBodyformBody");
        if (data && data?.status === true) {
          onSuccess(dispatch, data?.message);
          localStorage.setItem("token", data?.data?.accesstoken);
          dispatch(
            authAction.onUpdateUserToken(data?.data?.accesstoken || undefined)
          );

          if (values.check) {
            let remObj = {
              flag: values.check,
              mobileNumber: values.mobileNumber,
              password: values.password,
            };
            dispatch(onRememberMeHandlerMd(true, remObj));
          } else {
            dispatch(onRememberMeHandlerMd(false));
          }
          navigate("/dashboard");
        } else {
          onFailedMsg(dispatch, data.error);
        }
      } catch (e) {
        onFailedMsg(dispatch, e?.response?.data?.error);
      } finally {
        setBtnLoading(false);
      }
    }
  };

  return (
    <main className="login_main_box">
      <div className="container-fluid">
        <div className="row no-gutter">
          <div className="col-lg-6 col-sm-12 col-md-12 col-12  bg-imagae d-none d-lg-flex">
            <div className="logo-box ">
              <BgImage />
            </div>
          </div>

          <div className="col-lg-6 col-sm-12 col-md-12 col-12 bg-light">
            <div className="login d-flex align-items-center py-5">
              <div className="container">
                <div className="row">
                  <div className="col-lg-7 col-sm-12 col-md-11 col-11  mx-auto">
                    <img
                      className=" mobile_logo"
                      src="/assets/images/logo1.png"
                      alt="logo-img"
                      width="256"
                    />
                    <h5 className="logi-font-bod">Login</h5>
                    <h2 className="logi-font-box">welcome back!</h2>
                    <p className="logi-font-box-a mb-4">
                      {" "}
                      {/* Please login to your account. */}
                    </p>
                    <form className="" autoComplete="off" onSubmit={onSubmit}>
                      <div className="form-floating mb-3">
                        <PhoneInput
                          inputProps={{
                            name: "phone",
                            required: true,
                            id: "mobileNum",
                            autoFocus: true,
                          }}
                          disabled={btnLoading}
                          disableDropdown={true}
                          countryCodeEditable={false}
                          country={"in"}
                          preferredCountries={["in"]}
                          value={`+91 ${values?.mobileNumber}`}
                          onChange={handleChangeMobile}
                        />
                        {errors?.mobileNumber && (
                          <Error>{errors?.mobileNumber}</Error>
                        )}
                        {/* <label htmlFor="floatingInput">Mobile Number</label> */}
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          className="form-control"
                          id="floatingPassword"
                          placeholder="Password"
                          type={showPassword ? "text" : "password"}
                          name="password"
                          title="Password"
                          value={values.password}
                          onChange={onChnageValue}
                          maxLength={25}
                          disabled={btnLoading}
                        />
                        <span className="eye-password">
                          <ShowHidePwd
                            className="eye-password"
                            isShowPwd={!showPassword}
                            onTogglePassword={togglePassword}
                          />
                        </span>

                        {errors?.password && <Error>{errors?.password}</Error>}
                        <label htmlFor="floatingPassword">Password</label>
                      </div>

                      <div className="ling-remembe-box">
                        <div className="custom-control custom-checkbox mb-2 ">
                          <input
                            id="customCheck1"
                            type="checkbox"
                            className="custom-control-input cursor-pointer"
                            checked={values.check}
                            disabled={btnLoading}
                            onChange={(e) =>
                              setValues((prev) => ({
                                ...prev,
                                check: e.target.checked,
                              }))
                            }
                          />
                          <label
                            htmlFor="customCheck1"
                            className="custom-control-label px-1 cursor-pointer"
                          >
                            Remember password
                          </label>
                        </div>
                        <Link to="/password/forget-password">
                          Forgot Password?
                        </Link>
                      </div>

                      <div className="text-right">
                        <button
                          style={{ color: "#fff" }}
                          type="submit"
                          className="btn btn-primary butt-nox btn-block text-uppercase mb-2  shadow-sm"
                          disabled={btnLoading}
                        >
                          {" "}
                          {btnLoading ? "Loading..." : "Login"}
                        </button>

                        <div className="account__switch">
                          <p className="m-0 p-0 pb-2">
                            Don't have an account?{" "}
                            <Link className="line_font" to="/register">
                              Sign up
                            </Link>
                          </p>

                          <Link to="/landing" className="mt-3">
                            <i className="fa-solid fa-arrow-left-long"></i>{" "}
                            <b>
                              {" "}
                              Back to&nbsp;
                              <span>Home</span>
                            </b>
                          </Link>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Login;
